.faq-listing-page {

  h1 {
    color: $fl_white;
  }
}

.faq-nigiri {
  
  &.tag_3369,
  &.tag_5042 {
    background-color: $fl_white !important;

    &::before {
      display: none !important;
    }
  }
}