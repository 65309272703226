.maki.card {
  @extend .p-2;
  background: $fl_white;
  transition: all 0.2s ease;
  .card-body {
    overflow: hidden;
  }

  .badge-container {
    font-size: 0.9rem !important;
    right: 7.5px;
    top: -10px;
    .sold-out-status_rescheduled {
      background-color: $warning;
      color: $black;
      padding: 0.3rem 0.75rem;
    }
    .sold-out-status_starts_soon {
      background-color: $info;
      color: $white;
      padding: 0.3rem 0.75rem;
    }
    .sold-out-status_sold_out.badge-danger {
      background-color: $cyan !important;
      color: $white !important;
      padding: 0.3rem 0.75rem;
    }
  }

  .card-title {
    text-align: left;
    justify-content: start;
    @extend .text-truncate;
    .title {
      //text-transform: uppercase;
      font-size: $h4-font-size;
      font-weight: 500 !important;
      font-family: $headings-font-family;
      color: $fl_black;
      text-transform: uppercase;
      @extend .text-truncate;
    }
    .sub-title {
      color: $fl_grey;
    }
    .price {
      color: $fl_black;
      @extend .mt-2;
      position: absolute;
      font-weight: bold;
      font-family: $headings-font-family;
      transform: rotate(-3deg);
      right: 1rem;
      top: calc(90vi - 38px);
      @include media-breakpoint-up(sm) {
        right: 1rem;
        top: 31rem;
      }
      @include media-breakpoint-up(md) {
        right: 1rem;
        top: 19rem;
      }
      @include media-breakpoint-up(lg) {
        right: 1rem;
        top: 16rem;
      }
      @include media-breakpoint-up(xl) {
        right: 1rem;
        top: 20rem;
      }
      box-shadow: $box-shadow-inverse;
      background: transparent url('./assets/images/pricetag.svg') center center no-repeat;
      background-size: contain;
      padding: 1rem 1.5rem;
    }
  }
  &:hover {
    //transform: scale(1.02);
    transition: all 0.2s ease;
    img {
      filter: brightness(1.2);
      transition: all 0.2s ease;
    }
  }
}
// hover effect
a.maki.card {
  // border: 2px solid transparent !important;
  // border-width: 2px !important;
  // border-color: transparent !important;
  // border-style: solid;
  // border-radius: 4px !important;
  &:hover {
    box-shadow: none;
    //border-color: rgba($color: $fl_primary, $alpha: 0.3) !important;
    transition: all 0.15s ease-in-out;
  }
}

// black firday maki tag 5998
.product-listing {

  // 5998 = black friday tag

  .maki .tag-5998 {
    color: transparent;
    background: transparent;
    position: absolute;
    right: 1rem;
    top: calc(90vi - 98px);
    @include media-breakpoint-up(sm) {
      right: 1rem;
      top: 27rem;
    }
    @include media-breakpoint-up(md) {
      right: 1rem;
      top: 15rem;
    }
    @include media-breakpoint-up(lg) {
      right: 1rem;
      top: 12rem;
    }
    @include media-breakpoint-up(xl) {
      right: 1rem;
      top: 16rem;
    }
    margin-bottom: 1.5rem;
    margin-right: 1rem;

    &::after {
      @extend .rounded-circle;
      content: '';
      display: inline-block;
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -20%);
      width: 90px;
      height: 90px;
      background: $white url('./assets/images/blackfriday.svg') no-repeat center center;
    }
  }
}