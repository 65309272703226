#footer {
  box-shadow: $box-shadow-inverse-xl;
  min-height: 30vh;
  background: $fl_black;

  color: $fl_white;
  line-height: 1.75rem;
  h5 {
    text-transform: uppercase;
    font-size: $h4-font-size;
  }
  a {
    color: $fl_white;
    text-decoration: none;
    &:hover {
      background-color: transparent;
    }
  }
  .copyright {
    font-size: 0.8rem;
    line-height: 1rem;
  }
  .copyright-logos {
    img {
      max-height: 2rem;
      filter: invert(1);
    }
    margin-top: 25px;
  }
}
