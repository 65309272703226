.product-content {
  background-color: transparent;
}

// Ticket Detail Page

.ticket-detail-page {
  .hero-image {
    background-image: url('./assets/images/head.gif');
    background-position: center top;
    background-size: cover;
    height: 75vh;
    @include media-breakpoint-down(md) {
      height: 50vh;
    }
    border-left: 1rem solid $fl-primary;
    border-right: 1rem solid $fl-primary;
  }
  
  .hero-image-overlay {
    width: 100%;
    opacity: 1;
    transition: opacity 0.2s;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
    body[data-scrolled] & {
      opacity: 0;
      transition: opacity 0.2s;
    }
  }
  .card {
    border: 0;
  }
  .blurry-background-image {
    background: linear-gradient(45deg, rgba($fl-purple, 0.5), rgba($fl-purple, 0.8)),
    linear-gradient(45deg, $fl_black, $secondary, $fl_black);
  }
  .product-info-bar {
    //background: linear-gradient(45deg, rgba(#3a1471, 0.5), transparent, rgba(#3a1471, 0.5)), linear-gradient(45deg, $fl_black, #2d2aa0, $fl_black);
    background: $fl-primary;
    color: $black;
    border-radius: 0;
    font-family: $headings-font-family;
    text-transform: uppercase;
    box-shadow: none;
    //a, .btn {color: $fl_white !important}
    .btn {color: $white;}
    i {
      color: $fl_primary !important;
    }
    .navbar-text {
      background-color: black;
      color: $fl_primary;
      border-radius: $border-radius-lg;
      padding: 0.75rem 1.5rem;
      font-weight: normal;
      margin: 0;
      @include media-breakpoint-up(md) {
        margin-right: .5rem;
      }
    }
    @include media-breakpoint-up(md) {
      .container {
        justify-content: flex-start !important;
      }
    }
  }

  .card.article-title {
    background: $fl_primary;
    //box-shadow: $box-shadow-xl;
    border-radius: 0;
    .super-title {
      color: $fl_black;
    }
    h1 {
      color: $fl_black;
      font-size: $h2-font-size;
      text-transform: uppercase;
      @include media-breakpoint-up(sm) {
        font-size: $h1-font-size;
      .title {

      }
      }
      small {
        color: $fl_black;
      }
    }
    .container {
      
    }
  }

  // TABS TICKET DETAIL

  article.ticket-detail {
    .DayPicker-Weekday {
      color: $black;
    }
    h2 {
      @extend .mb-0;
    }
    .nav-tabs {
      border-bottom: 2px solid $fl_secondary;
    }
    .nav-tabs .nav-link {
      color: $fl_secondary;
      border: 0;
      border-bottom: 1px solid $fl_secondary;
      @extend .py-3;
    }
    .nav-tabs .nav-link.active {
      background: $fl_secondary;
      color: $fl_white;
      border-bottom: 1px solid $gray-700;
      &:hover {
        //color: lighten($primary, 10%);
        background: rgba($fl_secondary, 0.8);
        color: $fl_white;
      }
    }
    .nav-tabs .nav-link:focus,
    .nav-tabs .nav-link:hover {
      border-bottom: 1px solid $gray-700;
      background: $gray-100;
      color: $gray-500;
    }
  }
}

// Product Detail Page

.detail-page {
  h1 {
    margin-top: 0;
    color: $fl_black;
    text-transform: uppercase;
    //text-transform: uppercase;
    font-size: $h2-font-size;
    .sub-title {
      color: $fl_grey;
    }
  }
  .product-header-title {
    line-height: 1.4;
  }
}

.carousel-control-prev,
.carousel-control-next {
  color: $primary;
  &:hover,
  &:focus {
    color: lighten($primary, 0.2);
  }
}

.blurry-background-image {
  background-color: transparent;
}

.carousel-indicators {
  @include media-breakpoint-down(sm) {
    display: none;
  }
  position: relative;
  > a,
  > .btn {
    img {
      filter: brightness(0.5);
      margin: 0.3rem !important;
    }

    &:hover,
    &:focus,
    &.active {
      img {
        filter: brightness(1);
      }
    }
  }
}

.product-thumb {
  box-shadow: none;
  border: none;
}
.radio-list-group .product-thumb {
  box-shadow: 0 0 0 1px $dark;
  border: 3px solid $white;
  background: $white;
}

// PDP MOBILE

.container.detail-page {
  @include media-breakpoint-down(md) {
    form {
      text-align: center;
    }
  }
}

.ticket-detail-page article.ticket-detail h2 {
  text-align: left !important;
}