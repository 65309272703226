body.g8-page {
  background-color: $black;
  background: $fl-primary !important;
  // background: linear-gradient(rgba($fl-primary, 0.7), rgba($fl-primary, 0.7)), url('./assets/images/head.gif');
  // background-size: cover;
  // background-repeat: no-repeat;
  // background-position: center;
  // background-position-x: center;
  // background-position-y: center;
  color: $black;
  min-height: 100vh;
  max-height: 100vh;
  overflow: auto !important;
  font-family: 'normalidad-text', sans-serif;

  .g8-page-content {
    padding: 0px;
  }

  .logo-wrapper {
    margin-bottom: 20px;
  }

  .logo:hover {
    filter: brightness(1.2);
  }
  .main-wrapper {
    min-height: 100vh;
    max-height: 100vh;
    width: 100vw;
    min-width: 100vw;
    overflow: auto;
    // border: 1rem solid $fl-primary;
  }
  // .wait-wrapper .card {
  //   color: $fl-primary;
  //   background-color: black;
  //   border-radius: 4rem;
  //   border: 0;
  // }
  // .countdown-wrapper .card {
  //   color: $fl-primary;
  //   background-color: black;
  //   border-radius: 4rem;
  //   border: 0;
  // }
  // .go-wrapper .card {
  //   color: $fl-primary;
  //   background-color: black;
  //   border-radius: 4rem;
  //   border: 0;
  // }
  .btn {
    text-transform: uppercase;
    border-radius: 2rem;
  }
  .form-control {
    border-radius: 2rem;
  }

  // .lead {
  //   font-size: 20px;
  //   font-weight: 300;
  //   line-height: 30px;
  //   text-align: center;
  //   text-size-adjust: 100%;
  // }
  // h1 {
  //   font-size: 2rem;
  //   font-weight: 700;
  //   text-align: center;
  //   text-size-adjust: 100%;
  //   text-transform: uppercase;
  // }
  // div.text-center.small {
  //   font-size: 14px;
  //   font-weight: 400;
  //   line-height: 21px;
  //   text-align: center;
  // }
}

// @media (max-width: 600px) {
//   body.g8-page {
//     background-position-x: -50vw;
//   }
// }

.countdown, 
.position {
  font-size: $h5-font-size;
  background-color: $fl-black;
  border-radius: 2rem;
  color: $fl-primary;
  padding: 0.5rem 1.5rem;
  text-transform: uppercase;
}

b {
  font-weight: 700 !important;
}

.message-wrapper {
  box-shadow: $box-shadow-xl;
  border-radius: 1rem !important;
}