body {
  font-family: $fl_font;
  background-color: $fl_black !important;
}

#root {
  padding-top: 0;
}

#main {
  background-color: $fl_white;
  //backdrop-filter: blur(10px);
  flex-grow: inherit;
}

.btn,
.loading-spinner-content {
  text-transform: uppercase;
  font-family: $headings-font-family;
}

.btn-primary {
  font-weight: 400;
}

.btn-lg {
  border-radius: 2rem;
}

::selection {
  background-color: $fl_black;
  color: $fl_primary;
}

a {
  color: $fl_black;
}

h1, h2, h3, h4, h5, legend {
  text-transform: uppercase;
}

#footer ul a,
#footer address a {
  &:hover {
    color: $fl_primary;
  }
}

body.product-listing-page,
body.ticket-listing-page,
body.faq-listing-page {
  h1 {
    padding-bottom: 4rem !important;
  }
  background: $fl-primary;
  // background-attachment: fixed;
  // background-size: cover;
  #main {
    // background-color: rgba($fl_black, 0.1) !important;
    // backdrop-filter: blur(25px);
    background: $fl-primary;
  }
}

.checkout .when-valid.is-valid,
form .when-valid.is-valid,
.shoppingcart-finisher {
  background-color: $fl-primary;
  box-shadow: none;
  .btn-primary {
    background-color: $black;
    color: $white;
    font-weight: 400;
  }
}


// SELL OUT

body.sell-out {
  $florange: #ff8000;
  #header {
    display: none;
  }
  #main {
    background-color: $florange;
    min-height: 100vh;
    a {
      background-color: black;
      min-width: 300px;
      display: inline-block;
      text-align: center;
      color: $florange;
      border-radius: 25px;
      padding: 10px 30px;
      font-weight: bold;
      text-transform: uppercase;
      &:hover {
        color: $white;
        text-decoration: none;
      }
    }
  }
  .h-100 {
    min-height: 100vh;
    flex-direction: column;
  }
}
