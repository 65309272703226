body.product-listing-page,
body.ticket-listing-page {
  .btn-tickets {
    background-color: $fl-black;
    color: $fl-primary
  }
  .hero-image {
    background-image: url('./assets/images/head.gif');
    background-position: center top;
    background-size: cover;
    height: 75vh;
    @include media-breakpoint-down(md) {
      height: 50vh;
    }
    border-left: 1rem solid $fl-primary;
    border-right: 1rem solid $fl-primary;
  }
  
  .hero-image-overlay {
    width: 100%;
    opacity: 1;
    transition: opacity 0.2s;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
    body[data-scrolled] & {
      opacity: 0;
      transition: opacity 0.2s;
    }
  }
}

#ticket-listing {
  a {
    text-decoration: none !important;
  }
}