#header {
  box-shadow: $box-shadow-xl;
  background: $fl-primary;

  .item-count {
    background-color: $fl-secondary;
  }

  .navbar {
    padding: 1rem;
    font-size: $h5-font-size;
    //background-color: $black !important;

    .nav-link {
      color: $fl_black;
      font-family: $headings-font-family;
      font-weight: 400;
      text-transform: uppercase;
      padding: 0.25rem 1rem;
      border: 2px solid transparent;
      border-radius: 1.75rem;
      &:hover {
        color: rgba($fl_black, 50%);
        border: 2px solid rgba($fl_black, 50%);
      }
      &.active {
        color: $fl_primary;
        background-color: $fl_black;
        border: 2px solid $fl_black;
      }
    }

    .navbar-brand {
      padding: 0;

      img {
        height: 3rem;
        max-height: 3rem;
        //filter: invert(1);
        @include media-breakpoint-up(sm) {
          height: 3rem;
          max-height: 3rem;
        }
        @include media-breakpoint-up(md) {
          height: 3rem;
          max-height: 3rem;
        }
        &:hover,
        &:focus {
          transform: rotate(180deg);
        }
      }
    }

    .hamburger {
      color: $fl_black !important;
      z-index: 3;
      margin-top: 2px;
      @include media-breakpoint-down(sm) {
        padding-right: 0px;
      }
      &:hover, &.active {
        border: 2px solid transparent;
      }
    }
    .hamburger.is-active .hamburger-inner,
    .hamburger.is-active .hamburger-inner:after,
    .hamburger.is-active .hamburger-inner:before {
      background-color: $fl_black !important;
    }
    .hamburger-inner,
    .hamburger-inner:after,
    .hamburger-inner:before {
      height: 2px;
    }

    .fal {
      font-weight: 500;
    }
  }
}

.navbar .secondary-menu,
.navbar .main-menu {
  display: flex;
  align-items: center;
  transition: all 0.2s;
}

.navbar-toggler {
  border: none;
  transition: transform 0.4s;
  padding: 0;
  outline: 0 !important;

  .navbar-toggler-icon {
    width: 2em;
    height: 2em;
  }
}

// hide cart countdown on tiny displays
.navbar .nav-link .badge.expiration {
  display: none;
  @media only screen and (min-width: 500px) {
    display: inherit;
  }
}

.offcanvas-collapse {
  top: 75px;
  .navbar-nav .nav-item {
    font-family: $headings-font-family;
    margin-bottom: 0.5rem;
    // font-size: $h2-font-size;
    color: $fl_black !important;
    // text-transform: uppercase;
    //box-shadow: -20px 0px 20px 0px rgba(0, 0, 0, 0.5);
    
  }
}

.offcanvas-collapse-left {
  background: $fl-primary !important;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 2rem;
}

.offcanvas-collapse-right {
  background-color: $fl-primary;
  .cart-listing {
    background: rgba(255, 255, 255);
  }
  // .coupon-container button:hover {
  //   color: white;
  // }
  font-size: $font-size-base;
  .btn-primary {
    background-color: $fl_black;
    color: $white;
  }
}

.validation-alert {
  @extend .text-danger;

  .validation-alert--message {
    @extend .font-weight-bold;
  }
}
