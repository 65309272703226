// <- FL TillReiners <- Kaffkiez <- JORIS SKIN <- FE SKIN <- BF SKIN <- JIRAFA SKIN

@import './general';
@import './navigation';
@import './maki';
@import './nigiri';
@import './detail-pages';
@import './listing';
@import './faq';
@import './checkout';
@import './footer';
@import './g8';

// OTHER

.hero-slider {
  margin-bottom: 0;
}

.stage {
  //box-shadow: $box-shadow-xl;
  img {
    &:hover {
      filter: brightness(1.2);
    }
  }
}

body[data-scrolled] {
  .stage {
    opacity: 0.5;
    transition: all 0.2s ease;
    //transform: translateY(0);
  }
}

.boxheader {
  text-transform: uppercase;
  text-align: center;
}

// CARMENS SPECIALS
// BACKDROP

.modal-backdrop.show {
  //backdrop-filter: none !important;
}

.radio-list-group,
.navbar-nav .nav-item {
  box-shadow: none !important;
}

legend {
  font-weight: $headings-font-weight;
}

// FIXES

.tt-sticky-top {
  top: 0;
}



// fix for add to cart button scrolled out of sight on iPhones.
// spinner is hidden so that add to cart button is aligned.

@include media-breakpoint-only(xs) {
  table.variant-listing {
    display: grid;
    grid-template-columns: auto;
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }

    thead {
      display: none;
    }

    tbody,
    tr {
      display: inherit;
      margin-bottom: 1rem;
      background-color: $white !important;
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    tr {
      grid-template-rows: auto;
      box-shadow: $box-shadow;
      //border: 1px solid $gray-200;
      th {
        padding-bottom: 0.1rem;
      }
      td:first-of-type {
        border-top: 0;
        padding-top: 0.1rem;
      }
    }

    tbody tr td,
    tbody tr th {
      text-align: left !important;
    }

    fieldset.number-input {
      button,
      input {
        width: stretch;
        width: -webkit-fill-available;
        width: fill-available;
      }
    }

    colgroup {
      display: none;
    }

    div.invisible.spinner-border {
      display: none !important;
    }

    button.add-product {
      width: 100%;
      max-width: 100%;
      span {
        display: inline !important;
      }
    }
  }
}

// safari fix from-to dates on nigiri
@include media-breakpoint-up(lg) {
  .nigiri-date {
    text-align: center;
    flex-direction: column;
  }

  .nigiri-date * + * {
    margin-top: -1rem;
  }
}

article#article-54788 {
  .nigiri,
  .card-body {
    background-color: #fc5628;
    color: #fff !important;
  }
  .card-body {
    justify-content: center !important;
    align-items: center !important;
  }
  .location,
  .subtitle {
    color: #fff !important;
  }
  span.city {
    display: none;
  }
  .btn-primary {
    background-color: #000;
    border-color: #000;
  }
}

.alert-fl {
  background-color: $fl-black;
  color: $fl-primary;
  font-family: $headings-font-family;
  font-size: $h5-font-size;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  padding: 2rem;
}