.nigiri.card {
  background-color: $fl_primary;
  border: 1.5px solid $fl_black;
  font-family: $headings-font-family;
  transition: all 0.2s ease;
  border-radius: 0;

  // TBA
  &.tag_3369 {
    background-color: rgba($fl-white, 0.8);
    &::before {
      display: block;
      position: absolute;
      left: -5px;
      content: 'NACHHOLTERMIN';
      background-color: $fl_black;
      color: $fl_white;
      font-family: $headings-font-family;
      font-size: $h6-font-size;
      font-weight: 400;
      transform: rotate(-3deg);
      padding: 0.25rem 0.5rem;
      @extend .badge;
      box-shadow: $box-shadow;
    }
  }

  // Zusatzshow
  &.tag_5042 {
    &::before {
      display: block;
      position: absolute;
      left: 1.25rem;
      top: -10px;

      .sold-out-status_rescheduled {
        background-color: $warning;
        color: $black;
      }

      content: 'Zusatztermin';
      background-color: $fl-black;
      color: $fl_white;
      font-family: $headings-font-family;
      font-size: 0.9rem !important;
      font-weight: normal;
      padding: 0.3rem 0.75rem;
      @extend .badge;
    }
  }

  .badge {
    font-size: 0.9rem !important;
    padding: 0.3rem 0.75rem;
    font-weight: normal;
    right: 1.25rem;
    top: -11px;
    &.sold-out-status_rescheduled {
      background-color: $warning;
      color: $black;
    }
    &.sold-out-status_starts_soon {
      background-color: $info;
      color: $white;
    }
    &.sold-out-status_sold_out.badge-danger {
      background-color: $cyan !important;
      color: $white !important;
    }
  }

  .nigiri-content {
    margin-top: 0.2rem;
    color: $fl_black;
    @include media-breakpoint-down(md) {
      display: table;
    }

    .sold-out-status_sold_out.badge-danger {
      transform: none;
      background-color: $fl_black;
      color: $fl_black;
      z-index: 2;
      font-size: 0.8em;
    }

    .nigiri-date {
      font-size: $h4-font-size;
      font-weight: 600 !important;
    }
    .nigiri-body {
      font-size: $h2-font-size;
      border-left: 0;
      //text-transform: uppercase;
      font-weight: 600;
      @include media-breakpoint-down(md) {
        padding-left: 0;
        line-height: 2rem;
      }
    }
    .nigiri-cta {
      @include media-breakpoint-down(md) {
        justify-content: flex-end;
      }
    }
    .nigiri-cta .btn {
      color: $fl_primary;
      background: $fl_black;
      @include media-breakpoint-down(md) {
        display: inline !important;
        width: auto !important;
      }
    }
  }
  .title {
    font-size: 0.6em;
    margin-bottom: 0;
    color: $fl_black;
  }
  .subtitle {
    font-size: 0.5em;
    margin-bottom: 0;
    color: $fl_black;
    font-weight: normal;
    @include media-breakpoint-down(md) {
      line-height: 1rem;
      margin-bottom: 0.5rem;
    }
  }
  .location {
    font-size: $h4-font-size !important;
    font-weight: 400;
    text-transform: uppercase;
    color: $fl_black;
    @include media-breakpoint-down(md) {
      margin-bottom: 1rem;
    }
  }
  .city {
    font-weight: 600;
  }
  .location-name {
    @include media-breakpoint-down(md) {
      display: block;
      font-size: 80%;
    }
  }
  &:hover {
    // transform: scale(1.02);
    // transition: all 0.2s ease;
    color: $fl-primary;
    background-color: $black;
    .nigiri-content, .location, .subtitle, .title {
      color: $fl-primary;
    }
  }
}

// auto sold-out

.button-sold-out-status_sold_out {
  opacity: 0.5;
}

.btn.btn-primary.button-sold-out-status_sold_out:hover {
  background-color: transparent !important;
}